import { parseDate } from "chrono-node";
import { addDays, format, setHours, setMinutes } from "date-fns";
import { FieldValues, UnpackNestedValue } from "react-hook-form";
import { EventColor, PrimaryCategory } from "../../../reclaim-api/EventMetaTypes";
import { Smurf } from "../../../reclaim-api/Projects";
import { Task } from "../../../reclaim-api/Tasks";
import { notNull, notUndefined } from "../../../types";
import { DATE_TIME_DISPLAY_FORMAT, durationStr, isDateNowOrEarlier } from "../../../utils/dates";
import { parseDuration } from "../rhf/DurationControl";
import { FormValidators } from '..';

export type TaskFormValues = {
  title: string;
  eventColor: string | null;
  eventCategory: string | null;
  timeChunksRequired: string;
  _additionalChunksRequired: string | null;
  snoozeUntil: string;
  due: string;
  minChunkSize: string;
  maxChunkSize: string;
  notes: string;
  priority: Smurf | null;
  alwaysPrivate: boolean;
};

export const newTaskFormValues = (defaults: FieldValues): UnpackNestedValue<TaskFormValues> => ({
  title: "",
  eventColor: EventColor.Auto.key,
  eventCategory: PrimaryCategory.SoloWork.key,
  timeChunksRequired: "",
  _additionalChunksRequired: "",
  snoozeUntil: "",
  due: "",
  minChunkSize: "",
  maxChunkSize: "",
  notes: "",
  priority: Smurf.Default,
  alwaysPrivate: defaults.alwaysPrivate !== undefined ? defaults.alwaysPrivate : false,
});

export const defaultFormValues = (defaults?: FieldValues): UnpackNestedValue<TaskFormValues> => ({
  title: "",
  eventColor: EventColor.Auto.key,
  eventCategory: PrimaryCategory.SoloWork.key,
  timeChunksRequired: defaults?.timeChunksRequired || "1 hr",
  _additionalChunksRequired: "",
  snoozeUntil: "now",
  due: !!defaults?.due ? format(addDays(setMinutes(setHours(new Date(), 20), 0), 3), DATE_TIME_DISPLAY_FORMAT) || "" : "",
  minChunkSize: defaults?.minChunkSize || "1 hr",
  maxChunkSize: defaults?.maxChunkSize || "2 hr",
  notes: "",
  priority: Smurf.Default,
  alwaysPrivate: defaults?.alwaysPrivate !== undefined ? defaults.alwaysPrivate : false,
});

const parseDateField = (date: string) => parseDate(date, undefined, {forwardDate: true})

export const dataToValues = (data: Partial<Task> | null, defaults?: FieldValues): FieldValues => {
  const defaultValues = defaultFormValues(defaults);

  if (!data) return newTaskFormValues(defaults || {});

  /**
   * TODO (SS): This logic makes it so dates in the past are not displayed as valid input.
   * This is hopefully a temporary solution to avoid cases where the date jumps one year into
   * the future if it is a past date.
   */
  const setDateField = (date: Date, defaultValue: string): string | undefined => {
    if (!!date && isDateNowOrEarlier(date)) {
      return defaultValue;
    } else {
      return !!date ? format(date, DATE_TIME_DISPLAY_FORMAT) : undefined;
    }
  }

  let values: FieldValues = {
    title: data.title,
    eventColor: data.eventColor?.key,
    eventCategory: data.eventCategory?.key,
    timeChunksRequired: durationStr((data.timeChunksRequired as number) * 15 * 60, true),
    due: !!data.due ? format(data.due as Date, DATE_TIME_DISPLAY_FORMAT) : undefined,
    minChunkSize: durationStr((data.minChunkSize as number) * 15 * 60, true),
    notes: data.notes,
    maxChunkSize: durationStr((data.maxChunkSize as number) * 15 * 60, true),
    priority: data.priority,
    alwaysPrivate: data.alwaysPrivate,
  };

  values.snoozeUntil = setDateField(data.snoozeUntil as Date, defaultValues.snoozeUntil);
  
  // TODO (SS): This date being in the past should not happen because tasks are marked completed
  // when they are past due. This will protect us in the meantime from dates forwarding a year.
  values.due = setDateField(data.due as Date, "") || "";

  values = Object.entries(values).reduce((acc, [k, v]) => {
    if (notUndefined(v) && notNull(v)) acc[k] = v;
    return acc;
  }, {});

  return { ...defaultValues, ...values };
};

export const valuesToData = (values: FieldValues, defaults?: FieldValues): Partial<Task> => {
  // First add the missing defaults:
  const defaulted: FieldValues = Object.entries(values).reduce((acc, [k, v]) => {
    if ((v === undefined || v === "") && defaults?.[k]) {
      acc[k] = defaults[k];
    } else {
      acc[k] = v;
    }
    return acc;
  }, {});

  // remove any empty fields
  const data: Partial<Task> = Object.entries(defaulted).reduce((acc, [k, v]) => {
    if (v !== undefined && v !== "") acc[k] = v;
    return acc;
  }, {});

  if (defaulted.eventColor) data.eventColor = EventColor.get(defaulted.eventColor);
  if (defaulted.eventCategory) data.eventCategory = PrimaryCategory.get(defaulted.eventCategory);
  if (defaulted.timeChunksRequired)
    data.timeChunksRequired = (parseDuration(defaulted.timeChunksRequired) as number) / 15;
  
  if (defaulted.snoozeUntil) data.snoozeUntil = parseDateField(defaulted.snoozeUntil);
  if (defaulted.due === "") data.due = null;
  else if (defaulted.due) data.due = parseDateField(defaulted.due);
  
  if (defaulted.minChunkSize) data.minChunkSize = (parseDuration(defaulted.minChunkSize) as number) / 15;
  if (defaulted.maxChunkSize) data.maxChunkSize = (parseDuration(defaulted.maxChunkSize) as number) / 15;

  return data;
};

export const TaskFormValidators: FormValidators = {
  dueDate: (value: string, snoozeUntil: string) => {
    const parsed = parseDateField(value);
    const snoozeParsed = parseDateField(snoozeUntil);
    return !!parsed && !!snoozeParsed && parsed.getTime() <= snoozeParsed.getTime()
      ? "must be after scheduled start"
      : true;
  },
  maximumMinChunkSize: (value: string, maxChunkSize: string) => {
    const parsed = parseDuration(value);
    const maxParsed = parseDuration(maxChunkSize);
    return !!parsed && !!maxParsed && parsed > maxParsed ? "cannot exceed maximum block duration" : true;
  },
  maximumChunkSize: (value: string, timeChunksRequired: string) => {
    const parsed = parseDuration(value);
    const maxParsed = parseDuration(timeChunksRequired);
    return !!parsed && !!maxParsed && parsed > maxParsed ? "cannot exceed total time" : true;
  },
};
